/** Inter Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* ? Defining variables */
:root{
  --background_color: #23252C;
  --card_body_color: #1A1B21;
  --primary_text_color: #FFFFFF;
  --secondary_text_color: #DCDCDC;
  --accent_color: #F3BF99;
  --btn_blue_color: #5093E2;
  --icon_bg_color: #161619;
  --icon_color: #918E9B;
  --email_hover: #FFF0d9;
  --resume_hover: #12c6d3;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.wrapper{
  background-color: var(--background_color);
  color: var(--primary_text_color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.card{
  background-color: var(--card_body_color);
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 80vh;
  /* justify-content: center; */
  align-items: center;
  border-radius: 0.8rem;
  box-shadow: 3px 3px 15px 2px #000;
  transition: all 0.4s ease-in-out;
}

.card-img{
  background-image: url('../images/final_college_pic.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 40%;
  border-radius: 0.8rem 0.8rem 0 0;
}

.card-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  padding: 10px;
}

.name{
  font-size:25px;
  font-weight: bold;
}

.title{
  font-size:13px;
  font-weight: 500;
  color: var(--accent_color);
}

.username{
  font-size: 11px;
  color: var(--secondary_text_color);
  text-decoration: none;
  transition: 0.5s ease-in;
}

.username:hover{
  color: var(--btn_blue_color);
}

.card-footer{
  width: 100%;
  height: 10%;
  background-color: var(--icon_bg_color);
  border-radius: 0 0 0.8rem 0.8rem;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.footer-icon{
  font-size: 30px;
  color: var(--icon_color);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.footer-icon:hover{
  color: var(--primary_text_color);
}

.btn-container{
  width: 80%;
  display:flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-around;
}

.btn{
  padding: 0.8rem 1rem;
  margin-right: 10px;
  border-radius: 0.6rem;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #DCDCDC;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}

.btn:hover{
  box-shadow: -3px 3px 10px 2px #000;
}

.email-btn{
  background-color: var(--primary_text_color);
  color: var(--background_color);
  text-decoration: none;
  box-shadow:inset 0 0 0 0 var(--email_hover);
  transition: 0.9s ease-out;
}

.email-btn:hover{
  box-shadow: inset 300px 0 0 0 var(--email_hover),-3px 3px 10px 2px #000;
}

.linked-btn{
  background-color: var(--btn_blue_color);
  color: var(--primary_text_color);
  text-decoration: none;
  box-shadow:inset 0 0 0 0 var(--resume_hover);
  transition: 0.9s ease-out;
}

.linked-btn:hover{
  box-shadow:inset 300px 0 0 0 var(--resume_hover),-3px 3px 10px 2px #000;
}

.about{
  width: 80%;
  margin-top: 30px;
}

.content-title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.content-desc{
  font-size: 11px;
  font-weight: 300;
  color: var(--secondary_text_color);
}

.skill{
  width: 80%;
  margin-top: 25px;
}

@media only screen and (max-width: 400px) {
  .card{
    width: 80vw;
    height: 80vh;
  }

  .name{
    font-size:15px;
  }
  
  .title{
    font-size:10px;
  }
  
  .username{
    font-size: 8px;
  }

  .btn{
    padding: 0.6rem 0.8rem;
    margin-right: 8px;
    border-radius: 0.6rem;
    font-size: 12px;
    font-weight: 700;
  }

  .content-title{
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  
  .content-desc{
    font-size: 10px;
    font-weight: 300;
    color: var(--secondary_text_color);
  }

  .footer-icon{
    font-size: 25px;
  }
}

@media only screen and (max-height: 800px){


  .name{
    font-size:16px;
  }
  
  .title{
    font-size:12px;
  }
  
  .username{
    font-size: 9px;
  }

  .btn{
    padding: 0.4rem 0.4rem;
    margin-right: 8px;
    border-radius: 0.6rem;
    font-size: 10px;
    font-weight: 700;
  }

  .content-title{
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 2px;
    text-transform: uppercase;
  }
  
  .content-desc{
    font-size: 10px;
    font-weight: 200;
    color: var(--secondary_text_color);
  }

  .footer-icon{
    font-size: 25px;
  }

  .about{
    margin-top: 14px;
  }

  .skill{
    margin-top: 12px;
  }

  .email-icon{
    display: none;
  }

  .resume-icon{
    display: none;
  }
}

@media only screen and (max-height: 550px){
  
  .skill{
    display:  none;
  }
}

@media only screen and (max-width: 400px) and (max-height: 700px){
  .name{
    font-size:14px;
  }
  
  .title{
    font-size:10px;
  }
  
  .username{
    font-size: 8px;
  }

  .btn{
    padding: 0.3rem 0.3rem;
    margin-right: 8px;
    border-radius: 0.6rem;
    font-size: 10px;
    font-weight: 700;
  }

  .content-title{
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 2px;
    text-transform: uppercase;
  }
  
  .content-desc{
    font-size: 9px;
    font-weight: 200;
    color: var(--secondary_text_color);
  }

  .footer-icon{
    font-size: 22px;
  }

  .about{
    margin-top: 14px;
  }

  .skill{
    margin-top: 12px;
  }

  .email-icon{
    display: none;
  }

  .resume-icon{
    display: none;
  }
}